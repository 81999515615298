import { Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';

export default class NewCategoryModel {
    constructor(category: string) {
        this.category = category;
    }

    @Expose()
    @IsNotEmpty()
    category!: string;
}
